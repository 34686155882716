export interface OrderExtend {
    "order": Order,
    "lineItems": Array<LineItems>,
    "payments": Array<Payment>
}

export enum OrderStatus {
    UnAuthorize = "UnAuthorize", Authorize = "Authorize", Cancel = "Cancel", Abort = "Abort", Finish = "Finish"
}

export enum OrderMode {
    None = "None", FreeSolution = "FreeSolution", Standard = "Standard", Manual = "Manual", Contract = "Contract"
}
export enum PartnerType {
    None = "None", Metro = "Metro"
}
export interface Order {
    "status": OrderStatus,
    "mode": OrderMode,
    "guid": string,
    "space": string,
    "runningNumber": string,
    "user": string,
    "remark": string,
    "contract": string,
    "createTime": string,
    "partner": PartnerType,
    "partnerUserId": string
}

export enum ItemType {
    Token = "Token", Coupon = "Coupon", ContractUserDiscount = "ContractUserDiscount"
}

export enum ChargeType {
    Free = "Free", Point = "Point", CreditCard = "CreditCard"
}

export enum ChargeCurrency {
    Free = "Free", Point = "Point", TWD = "TWD"
}
export enum TaxCode {
    T = "T", N = "N", F = "F"
}
export interface Tax {
    "code": TaxCode,
    "guid": string,
    "rate": number,
    "description": string,
    "createTime": string
}
export enum RuleType {
    Close = "Close", Open = "Open", FixedOpen = "FixedOpen"
}
export interface Rule {
    "type": RuleType,
    "guid": string,
    "target": string,
    "expression": string,
    "timeSpan": number,
    "beginTime": string,
    "endTime": string,
    "createTime": string
}
export interface Charge {
    "type": ChargeType,
    "currency": ChargeCurrency,
    "tax": Tax,
    "rule": Rule,
    "guid": string,
    "target": string,
    "amount": number,
    "includeTax": boolean,
    "beginTime": string,
    "endTime": string,
    "createTime": string
}
export interface LineItems {
    "type": ItemType,
    "chargeExtend": Charge,
    "guid": string,
    "order": string,
    "payment": string,
    "item": string,
    "description": string,
    "quantity": number,
    "charge": string,
    "total": number,
    "createTime": string
}
export enum PaymentMethod {
    FreeByDiscount = "FreeByDiscount", TappayDirectPay = "TappayDirectPay", SpacesPoint = "SpacesPoint", Manual = "Manual", TappayGooglePay = "TappayGooglePay",
    TappayApplePay = "TappayApplePay", TappaySamsungPay = "TappaySamsungPay", TappayLinePay = "TappayLinePay", TappayJkoPay = "TappayJkoPay", TappayEasyWallet = "TappayEasyWallet"
}

export enum PaymentType {
    Free = "Free", Tappay = "Tappay", SpacesPoint = "SpacesPoint", Manual = "Manual"
}

export interface PaymentContact {
    "name": string,
    "phone": string,
    "email": string,
    "buyerBarCode": string
    "buyerId": string,
    "companyName": string
}
export enum PaymentServerStatus {
    Unpaid = "Unpaid", Captured = "Captured", Refunded = "Refunded"
}
export enum PaymentNeedCheckThreeParty {
    None = "None", NeedCheckThreeParty = "NeedCheckThreeParty"
}
export enum PaymentThreePartyStatus {
    None = "None", Authorized = "Authorized", Capturing = "Capturing", Captured = "Captured", PartialRefunded = "PartialRefunded",
    Refunded = "Refunded", Pending = "Pending", Cancel = "Cancel", Error = "Error"
}

export enum PaymentThreePartyResult {
    None = "None", Success = "Success", Error = "Error"
}

export enum PaymentNeedInvoice {
    None = "None", NeedInvoice = "NeedInvoice"
}

export enum PaymentHasInvoice {
    None = "None", HasInvoice = "HasInvoice"
}
export interface Payment {
    "method": PaymentMethod,
    "type": PaymentType,
    "currency": ChargeCurrency,
    "contact": PaymentContact,
    "guid": string,
    "space": string,
    "runningNumber": string,
    "amount": number,
    "order": string,
    "user": string,
    "payer": string,
    "captureTime": string,
    "createTime": string,
    "spacePaymentSetting": string,
    "serverStatus": PaymentServerStatus,
    "needCheckThreeParty": PaymentNeedCheckThreeParty,
    "threePartyStatus": PaymentThreePartyStatus,
    "threePartyResult": PaymentThreePartyResult,
    "needInvoice": PaymentNeedInvoice,
    "hasInvoice": PaymentHasInvoice
}